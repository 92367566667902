import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./ui/App"
import { CssBaseline } from "@mui/material"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import { store } from "./store/store"
import { AuthProvider } from "./ui/auth/AuthProvider"
import { Helmet } from "react-helmet"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)
root.render(
  <React.StrictMode>
    <Helmet>
      <meta name="apple-itunes-app" content="app-id=1625899714"></meta>
    </Helmet>
    <Provider store={store}>
      <CssBaseline>
        <AuthProvider>
          <App />
        </AuthProvider>
      </CssBaseline>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
